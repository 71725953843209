import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section

const theme = createTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistency
        values: {
            xs: 0,
            sm: 600,
            md: 1080,
            lg: 1200,
            xl: 1440,
        },
    },
    components: {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    borderRadius: '19px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation22: { // table bgs
                    borderRadius: 19,
                    background: 'none',
                    backgroundColor: 'white',
                    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        '& td': {
                            borderBottom: 0,
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                footer: { // no data
                    fontSize: 12,
                    color: Colors.grey1,
                    fontWeight: 700,
                    textAlign: 'center',
                    paddingTop: 30,
                    paddingBottom: 30,
                },
                root: {
                    paddingTop: 4,
                    paddingBottom: 4,
                    fontSize: 13,
                    backgroundColor: Colors.white,
                },
                head: {
                    fontSize: 12,
                    color: Colors.grey1,
                    letterSpacing: 0.4,
                    fontWeight: 600,
                    lineHeight: 1,
                },
                stickyHeader: {
                    height: 46,
                    backgroundColor: Colors.white,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    backgroundColor: 'none',
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: Colors.grey4,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    // backgroundColor: Colors.grey5,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '& > .MuiIconButton-label': {
                        '& > .MuiSvgIcon-root': {
                            fontSize: '1rem',
                        },
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&$selected': {
                        backgroundColor: Colors.grey6,
                    },
                    '&$selected$disabled': {
                        backgroundColor: Colors.white,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    lineHeight: 1.1875,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: Colors.black,
                    backgroundColor: Colors.white,
                    outline: 0,
                    width: '100%',
                    padding: '2px 0',
                    borderRadius: 3,
                    marginTop: 5,
                    lineHeight: '1.2rem',
                    '& $notchedOutline': {
                        border: `2px solid ${Colors.grey10}`,
                    },
                    '&:hover': {
                        '& $notchedOutline': {
                            border: `2px solid ${Colors.blue}`,
                        },
                    },
                    '&$focused': {
                        '& $notchedOutline': {
                            border: `2px solid ${Colors.blue}`,
                        },
                        borderRadius: 3,
                        color: Colors.blue,
                    },
                    '&$error$focused': {
                        '& $notchedOutline': {
                            border: `1px solid ${Colors.error}`,
                        },
                    },
                    '&$disabled': {
                        '& $notchedOutline': {
                            border: `2px solid ${Colors.grey10}`,
                        },
                        color: Colors.black,
                    },
                },
                input: {
                    borderRadius: 5,
                    padding: 10,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // make placeholder font size smaller
                input: {
                    '&::placeholder': {
                        fontSize: '14px !important',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    height: 42,
                    fontWeight: 400,
                    fontSize: 14,
                    color: Colors.grey8,
                    border: `1px solid ${Colors.grey9}`,
                },
                textSecondary: {
                    color: 'black',
                    fontWeight: 400,
                    fontSize: 14,
                    '&:disabled': {
                        color: Colors.grey6,
                    },
                },
                outlinedPrimary: {
                    backgroundColor: 'white',
                    minHeight: 50,
                    padding: '6px 30px',
                    color: Colors.blue,
                    border: `1px solid ${Colors.blue}`,
                    '&:hover': {
                        // backgroundColor: 'rgb(2, 66, 140)',
                    },
                },
                contained: {
                    padding: '6px 50px',
                    backgroundColor: '#e0e0e0',
                    color: Colors.black,
                    '&:hover': {
                        backgroundColor: '#d5d5d5',
                    },
                },
                containedPrimary: {
                    color: Colors.white,
                    backgroundColor: Colors.blue,
                    '&:hover': {
                        backgroundColor: 'rgb(2, 66, 140)',
                    },
                },
                containedError: {
                    color: Colors.white,
                    backgroundColor: Colors.error,
                    '&:hover': {
                        color: Colors.error,
                        backgroundColor: Colors.errorLight,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                button: {
                    verticalAlign: 'baseline',
                },
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Kumbh Sans, sans-serif',
        h1: {
            fontSize: 36,
            color: Colors.grey1,
            fontWeight: 700,
        },
        h2: {
            fontSize: 24,
            color: Colors.grey1,
            fontWeight: 700,
        },
        h3: {
            fontSize: 18,
            color: Colors.grey1,
            fontWeight: 700,
        },
        h4: {
            fontSize: 16,
            color: Colors.grey1,
            fontWeight: 700,
        },
        h7: {
            fontSize: 14,
            color: Colors.grey7,
            fontWeight: 700,
        },
        body1: {
            fontSize: 15,
            color: Colors.grey1,
            fontWeight: 400,
            lineHeight: '1.2rem',
        },
        body2: {
            fontSize: 15,
            fontWeight: 400,
            color: Colors.grey3,
        },
        subtitle1: {
            fontSize: 14,
            color: Colors.grey3,
        },
        button: {
            textTransform: 'none',
            fontWeight: 700,
            fontSize: 16,
            height: 50,
        },
        label: {
            fontSize: 14,
            color: '#0F2032',
            fontWeight: 700,
        },
    },
    palette: {
        text: {
            secondary: Colors.black,
        },
        primary: {
            main: Colors.blue,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.white,
            contrastText: Colors.black,
        },
        background: {
            default: Colors.pageBackground,
        },
        success: {
            main: Colors.success,
        },
        error: {
            main: Colors.error,
            contrastText: Colors.white,
        },
    },
});

export default theme;
